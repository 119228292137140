import React from "react";
import Layout from "../../../../other/layout";
import ConceptionDeVotreSiteWeb from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/ConceptionDeSitesWeb/ConceptionDeVotreSiteWeb";
import SEO from "../../../../other/seo";

export default function ConceptionDeVotreSiteWebPage() {
  return (
    <Layout>
      <SEO title="Conception de Sites Web" />
      <ConceptionDeVotreSiteWeb />
    </Layout>
  );
}
