import React from "react";
import styles from "./styles.module.css";
import achetez from "../../../../../assets/img/achetez.gif";
import designer from "../../../../../assets/img/web-design-header.jpg";
import expert1 from "../../../../../assets/img/expert1.gif";
import expert2 from "../../../../../assets/img/expert2.gif";
import expert3 from "../../../../../assets/img/expert3.gif";
import expert4 from "../../../../../assets/img/expert4.gif";

export default function ConceptionDeVotreSiteWeb() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>Services de conception de sites Web</h1>
        <p>
          Facile de démarrer! Choisissez un concept parmi des milliers de
          modèles professionnels. Naviguez dans notre banque de modèles. Nos
          experts en conception graphique Web ont une solution à la fois
          professionnelle et bon marché à tous vos besoins en matière de site
          Web.
        </p>
        <img
          src={designer}
          alt="Femme, conception, sur, ordinateur portable"
        ></img>
        <p>
          <span className="bold">Nous vous offrons:</span> Des services de
          consultation individuelle sur la conception de votre site La
          construction rapide et facile d'un site de 5 pages (HTML, optimisé
          Flash ou entièrement Flash) Programme d'entretien abordable pour une
          mise à jour régulière du site
        </p>
      </div>
      <div className={styles.row}>
        <h2>Contactez-nous en ligne ou appelez-nous aujourd'hui!</h2>
        <div className={styles.boxMainWrapper}>
          <div className={styles.boxMain}>
            <div className={styles.box_content_main}>
              <div className={styles.center}>
                <h2>
                  5 pages, <br />
                  entièrement
                  <br />
                  HMTL
                </h2>
                <img src={expert1} alt="5 pages entièrement" />
                <br />
                <span class="price">499,99 $</span>
                <div>paiement unique</div>
                <span class="price">49,99 $</span>
                <div>mois pendant 12 mois</div>
                <a href="http://manage.nebshosting.ca//cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207">
                  {" "}
                  <br />
                  <img src={achetez} alt="click here to order now" border="0" />
                </a>
              </div>
              <div class="box_content_main2">
                {" "}
                <br />
                <ul>
                  <li>Mise en page professionnelle</li>
                  <li>Temps de chargement plus court que les sites en Flash</li>
                  <li>Permet un excellent référencement</li>

                  <li>Voir les modèles</li>
                </ul>
                <div class="box_text" className={styles.center}>
                  Idéal pour les clients qui désirent un site Web professionnel
                  à un coût raisonnable.
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.boxMain}>
            <div className={styles.box_content_main}>
              <div className={styles.center}>
                <h2>
                  5 pages,
                  <br />
                  partiellement
                  <br />
                  FLASH
                </h2>
                <img src={expert2} alt="5 pages partiellement" />
                <br />
                <span class="price">699,99 $</span>
                <div>paiement unique</div>
                <span class="price">69,99 $</span>
                <div>mois pendant 12 mois</div>
                <a href="http://manage.nebshosting.ca//cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207">
                  {" "}
                  <br />
                  <img src={achetez} alt="click here to order now" border="0" />
                </a>
              </div>
              <div class="box_content_main2">
                {" "}
                <br />
                <ul>
                  <li>Menu interactif</li>
                  <li>Bannière animée, sons optionnels</li>
                  <li>Permet un bon référencement</li>

                  <li>Voir les modèles</li>
                </ul>
                <div class="box_text" className={styles.center}>
                  Idéal pour les clients qui désirent un site Web accrocheur
                  doté d'éléments animés
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.boxMain}>
            <div className={styles.box_content_main}>
              <div className={styles.center}>
                <h2>
                  5 pages,
                  <br />
                  entièrement <br />
                  FLASH{" "}
                </h2>
                <img src={expert3} alt="5 pages entièrement" />
                <br />
                <span class="price">849,99 $</span>
                <div>paiement unique</div>
                <span class="price">84,99 $</span>
                <div>mois pendant 12 mois</div>
                <a href="http://manage.nebshosting.ca//cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207">
                  {" "}
                  <br />
                  <img src={achetez} alt="click here to order now" border="0" />
                </a>
              </div>

              <div class="box_content_main2">
                {" "}
                <br />
                <ul>
                  <li>Toutes les caractéristiques Flash intégrées</li>
                  <li>Excellente qualité sonore</li>
                  <li>Pages animées avec son</li>

                  <li>Voir les modèles</li>
                </ul>
                <div class="box_text" className={styles.center}>
                  <br />
                  Idéal pour les clients désirant un site Web rehaussé qui fait
                  impression
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.boxMain}>
            <div className={styles.box_content_main}>
              <div className={styles.center}>
                <h2>
                  SOLUTION <br />
                  ESM <br />
                  PRO{" "}
                </h2>
                <img src={expert4} alt="SOLUTION ESM PRO" />
                <br />
                <span class="price">849,99 $</span>
                <div>paiement unique</div>
                <span class="price">84,99 $</span>
                <div>mois pendant 12 mois</div>
                <a href="http://manage.nebshosting.ca//cgi-bin/wairs/hc.signup.cgi?newpackage=mSOHOBASIC2207">
                  {" "}
                  <br />
                  <img src={achetez} alt="click here to order now" border="0" />
                </a>
              </div>
              <div class="box_content_main2">
                {" "}
                <br />
                <ul>
                  <li>Nouveau nom de domaine GRATUIT la première année!</li>
                  <li>
                    Mise sur pied et configuration d'une boutique virtuelle avec
                    insertion d'images du ou des produits
                  </li>
                  <li>
                    Solution basée sur l'outil de création EasyStoreMaker Pro
                  </li>
                  <li>25 produits et leurs images inclus</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
